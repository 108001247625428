.seat-block {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s linear;
}

.loaded .seat-block {
  visibility: visible;
  opacity: 1;
}

.active-seats .wc-block-components-quantity-selector {
  display: none;
}

.active-seats .wc-block-components-product-name {
  color: initial;
  text-decoration: unset;
  cursor: unset;
}

.seat-label {
  display: flex;
  align-items: center;
}

.seat-generated,
.product-name {
  display: block;
  margin-bottom: 10px;
}

.seat-generated .tc_cart_seat_remove {
  margin-left: 0;
}

/* During Remove item - Hide Duplicated seat-generated container */
.active-seats.is-disabled .seat-generated { display: none; }
.active-seats.is-disabled .wc-block-components-product-name .seat-generated { display: block; margin-top: 0; }
.active-seats.is-disabled .wc-block-components-product-name .seat-generated .product-name { display: block; margin-bottom: 10px; }
